<!-- @format -->

<template>
  <div>
    <table class="w-full">
      <thead class="bg-teal text-left">
        <th
          v-for="(heading, id) in tableHeaders"
          :key="id"
          class="px-6 py-4 text-white font-thin text-sm"
        >
          {{ heading }}
        </th>
      </thead>
      <tbody v-if="props.isDash">
        <tr v-for="(data, id) in tableBody" :key="id" class="border bottom-1">
          <td class="px-5 py-5 font-thin text-sm max-clamp truncate">
            {{ data?.nhs_number }}
          </td>
          <td class="px-5 py-5 font-thin text-sm">
            {{ data?.compliment_source }}
          </td>

          <td class="px-5 py-5 font-thin text-sm max-clamp truncate">
            {{ moment(data?.receiving_date).format("MMMM DD, YYYY") }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(data, id) in tableBody" :key="id" class="border bottom-1">
          <td class="px-5 py-5 font-thin text-sm max-clamp truncate">
            {{ data?.nhs_number }}
          </td>
          <td class="px-5 py-5 font-thin text-sm">
            {{ moment(data?.receiving_date).format("MMMM DD, YYYY") }}
          </td>

          <td class="px-5 py-5 font-thin text-sm max-clamp truncate">
            {{ data?.compliment_source }}
          </td>

          <td class="px-5 py-5 font-thin text-sm max-clamp truncate">
            {{ data.compliment_for }}
          </td>

          <td
            v-if="!isManager"
            class="px-5 py-5 font-thin text-sm max-clamp truncate"
          >
            {{ data.practice.practice_name }}
          </td>
          <td>
            <span
              class="border-teal border px-2 py-1 font-thin text-sm rounded-lg text-teal cursor-pointer hover:bg-teal hover:text-white"
              @click="navigate(parseInt(data.id))"
            >
              View Here</span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="bg-white flex justify-end text-teal px-3 py-2 text-sm items-center gap-1 cursor-pointer"
      @click="navigateToDash"
      v-if="props.isDash && isManager"
    >
      View Here
      <span class="text-teal material-icons text-lg"> launch</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

const props = defineProps({
  tableHeading: {
    type: Array,
    required: true,
  },
  tableBody: {
    type: Array,
    required: true,
  },
  isManager: {
    type: Boolean,
    required: true,
  },
  isDash: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();
const tableHeaders = computed(() => {
  if (isManager.value) {
    const headers = [...tableHeading.value];
    headers.splice(4, 1);
    return headers;
  } else {
    return tableHeading.value;
  }
});

const navigate = (complimentId) => {
  if (isManager.value) {
    router.push({
      name: "ma-monitoringOfPracticeFunctionCompliment-newCompliment",
      params: { view: 1, complimentId: complimentId, isManager: 1 },
    });
  } else {
    router.push({
      name: "hq-monitoringOfPracticeFunctionCompliment-newCompliment",
      params: { view: 1, complimentId: complimentId, isManager: 0 },
    });
  }
};

const navigateToDash = () => {
  router.push({
    name: "ma-monitoringOfPracticeFunctionComplimentDashboard",
    params: { isManager: 1 },
  });
};

const { tableHeading, tableBody, isManager } = toRefs(props);
</script>

<style scoped>
.max-clamp {
  max-width: 150px;
}
</style>
