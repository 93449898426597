<!-- @format -->

<template>
  <div :class="props.isDash ? 'w-full ' : 'w-1/3'">
    <div class="border-2 border-gray-100 bg-white rounded-xl">
      <div class="flex items-center justify-between px-4">
        <div class="py-4 font-medium text-gray-700">
          {{ heading }}
        </div>
        <div
          v-if="anyPendingCompliance && !props.isDash"
          class="relative group px-2"
          @mouseenter="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          <div
            class="material-icons px-1 bg-red-500 rounded-full text-base text-white shadow-md"
          >
            priority_high
          </div>
          <div
            v-if="showTooltip"
            class="absolute left-1/2 transform -translate-x-1/2 -top-10 mt-2 bg-red-50 border border-red-300 text-xs rounded py-1 px-2"
          >
            <div class="whitespace-nowrap capitalize">
              Pending {{ heading }}
              <div
                class="absolute left-1/2 transform -translate-x-1/2 w-2 h-2 bg-red-50 border-b border-red-300 border-r rotate-45"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="props.loader">
        <div
          role="status"
          class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border"
          v-for="key in props.isDash ? 1 : 7"
          :key="key"
        >
          <div
            class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
          >
            <svg
              class="w-10 h-10 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="flex items-center justify-between">
            <div>
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
              ></div>

              <div
                class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
              ></div>
            </div>
            <div class="pl-52 pb-3">
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12 my-2"
              ></div>
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="props.isDash && items?.length > 0">
          <div
            class="flex bg-teal bg-opacity-10 justify-between items-center px-4 py-2"
          >
            <div class="flex w-full">
              <span class="material-icons text-red-500 text-5xl w-12"
                >insert_drive_file_outlined</span
              >
              <div>
                <div class="line-clamp-1 w-3/4">
                  {{ items ? items[0]?.name : "" }}
                </div>
                <div>
                  {{
                    items
                      ? items[0]?.due_date
                        ? moment(items[0]?.due_date).format("MMM DD, YYYY")
                        : moment(items[0]?.end_date).format("MMM DD, YYYY")
                      : ""
                  }}
                </div>
              </div>
            </div>

            <div class="text-green bg-green bg-opacity-20 px-2 rounded">
              <div class="text-md whitespace-nowrap capitalize">
                {{
                  items
                    ? items[0]?.compliance_calendar_type?.name === "Policies"
                      ? items[0]?.practices[0]?.status === 4
                        ? "Reviewed"
                        : "Un Reviewed"
                      : items[0]?.practices[0]?.status === 1
                      ? "Submitted"
                      : "Pending"
                    : ""
                }}
              </div>
            </div>
          </div>
          <div
            class="bg-white flex justify-end text-teal px-3 py-2 text-sm items-center gap-1 cursor-pointer"
            @click="routeToPracticesResponse(items[0]?.id)"
          >
            View Here
            <span class="text-teal material-icons text-lg"> launch</span>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 px-5 border-gray-100 border-t-2 cursor-pointer"
            v-for="compliance in items?.items"
            :key="compliance?.id"
            @click="routeToPracticesResponse(compliance?.id)"
          >
            <div class="flex gap-2 break-all">
              <div class="w-10">
                <img
                  class="w-full"
                  src="../../../assets/icons/compliance_File.svg"
                />
              </div>
              <div class="w-3/4 text-base capitalize line-clamp">
                {{ compliance?.name }}
              </div>
            </div>
            <div class="flex items-center w-full gap-4 pl-10 pt-4">
              <div class="w-full">
                <div class="flex justify-between">
                  <div
                    class="status align-middle flex justify-center items-center w-max rounded-lg h-6 p-2"
                    :class="
                      compliance?.practices[0]?.status === 1 ||
                      compliance?.practices[0]?.status === 4
                        ? 'bg-green-50 text-green'
                        : 'bg-yellow-50 text-yellow'
                    "
                  >
                    <span
                      class="text-md capitalize"
                      :class="
                        compliance?.practices[0]?.status === 1 ||
                        compliance?.practices[0]?.status === 4
                          ? 'text-green'
                          : 'text-yellow-400'
                      "
                      >{{
                        compliance?.compliance_calendar_type?.name ===
                        "Policies"
                          ? compliance?.practices[0]?.status === 4
                            ? "Reviewed"
                            : "Un Reviewed"
                          : compliance?.practices[0]?.status === 1
                          ? "Submitted"
                          : "Pending"
                      }}</span
                    >
                  </div>
                  <div class="flex gap-1 font-light items-center text-gray-500">
                    <div class="flex font-light items-center">
                      <span
                        class="material-icons text-xl w-5 text-teal"
                        v-if="icon(compliance) !== 'custom'"
                      >
                        {{ icon(compliance) }}
                      </span>
                      <img
                        v-else
                        src="../../../assets/icons/calendar_clock.svg"
                        class="w-5"
                      />
                    </div>
                    {{
                      compliance?.due_date
                        ? moment(compliance?.due_date).format("MMM DD, YYYY")
                        : moment(compliance?.end_date).format("MMM DD, YYYY")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

const router = useRouter();

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  loader: {
    type: Boolean,
  },
  isDash: {
    type: Boolean,
    default: false,
  },
});

const showTooltip = ref(false);
const { items } = toRefs(props);

const checkPendingCompliance = (compliance) => {
  return (
    compliance?.practices[0]?.status === 0 ||
    compliance?.practices[0]?.status === 5
  );
};
const icon = (item) => {
  if (item?.practices[0].status === 1) {
    return "event_available";
  } else if (props.heading === "Policies") {
    return item?.practices[0].status === 4
      ? "event_available"
      : "calendar_month_icon";
  } else {
    return "custom";
  }
};
const anyPendingCompliance = computed(() => {
  if (!props.isDash)
    items.value?.items?.some((compliance) =>
      checkPendingCompliance(compliance)
    );
  return null;
});

const routeToPracticesResponse = (id) => {
  router.push({
    name: "ma-SubmitCompliance",
    params: { complianceId: id },
  });
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 300px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
